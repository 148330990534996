






















































































































































































































































import {
  defineComponent,
  computed,
  ref,
  onMounted,
  onUnmounted,
  reactive,
  nextTick
} from '@vue/composition-api';
import { Breakpoint } from 'vuetify/lib/services/breakpoint';
import { preset } from 'vuetify/lib/presets/default';
import { readFileSync } from 'fs';
import store from '../../../../store';
import { ActionTypes } from '../../../../store/modules/tools/actions';

export default defineComponent({
  name: 'SetupScopesWizard',
  props: {
    website: {
      required: true,
      type: String
    },
    scopes: {
      required: true,
      type: Array as () => string[]
    },
    loading: {
      required: true,
      type: Boolean
    },
    value: {
      required: true,
      type: String
    }
  },
  setup(props, context) {
    const { emit } = context;
    const scopeRefs = ref();
    const customScopeRef = ref();
    const scope__containerRef = ref();

    const editingCustomScope = ref(false);
    const customScopeTitle = ref('');
    const customScopeDetails = ref('');
    const customScope = computed<string>({
      get: () => {
        const title = customScopeTitle.value || '';
        const details = customScopeDetails.value || '';
        return title + (title && details ? '.' : '') + details;
      },
      set: value => {
        const sentences = value?.split('.');
        customScopeTitle.value = sentences?.shift() || '';
        customScopeDetails.value = sentences?.join('.');
      }
    });

    const displayScopes = reactive(
      props.scopes.map(scope => {
        const scopeSentences = scope.split('.');
        return {
          base: scope,
          title: scopeSentences[0],
          details: scopeSentences.slice(1).join('.')
        };
      })
    );

    const createProgramDialog = ref(props.loading);
    /** --- BEGIN EVENT EMITTERS --- */
    async function submitScope() {
      if (this.editingCustomScope) {
        store.dispatch(`tools/${ActionTypes.showSnackbar}`, {
          type: 'error',
          message: 'Scope not saved! Save before continuing',
          isShowSnackbar: true
        });
        return;
      }
      emit('submitScope');
      await nextTick();
      if (!props.value) {
        return;
      }
      this.createProgramDialog = true;
      await this.loadingData();
    }
    function selectScope(content) {
      emit('input', content);
      const refs = [customScopeRef.value, ...scopeRefs.value];
      const targetRef = refs.find(vref => {
        return vref.innerText.split('\n').join('. ') === content;
      });
      targetRef?.focus();
    }
    /** --- BEGIN HELPERS --- */
    const loadingDataValue = ref({
      value: 0,
      message: 'Creating your program'
    });
    async function loadingData(duration = 30, parts = 6) {
      const messages = [
        'Creating your program',
        'Prepping your program',
        'Sending selected scope',
        'Generating program details',
        'Creating program',
        'Loading Data'
      ];
      const step = duration / parts;
      for (let n = 0, i = 0; n < duration; n += step, i++) {
        if (!props.loading) break;
        // console.log(n, i);
        // eslint-disable-next-line no-await-in-loop
        await new Promise<void>(_res => {
          setTimeout(() => {
            this.loadingDataValue.value = (n / 30) * 100;
            this.loadingDataValue.message = messages[i];
            _res();
          }, step * 1000);
        });
      }
      this.loadingDataValue.value = 95;
      this.loadingDataValue.message = 'Finishing Up..';
    }
    function editScope(content) {
      this.customScope = content;
      this.selectScope(content);
      this.editingCustomScope = true;
      requestAnimationFrame(() => {
        this.scope__containerRef.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest'
        });
      });
    }
    const breakpoint = ref(new Breakpoint(preset));
    const tileHeightRate = computed(() => {
      switch (breakpoint.value.name) {
        case 'xl':
          return 60 / 3;
        case 'lg':
          return 90 / 3;
        case 'md':
          return 120 / 3;
        default:
          return 150 / displayScopes.values.length;
      }
    });
    /** --- BEGIN LIFECYCLE HOOKS --- */
    onMounted(() => {
      window.addEventListener('resize', () => breakpoint.value.update());
      requestAnimationFrame(() => {
        scope__containerRef.value.$el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest'
        });
      });
      selectScope(customScope.value);
      // editingCustomScope.value = true;
    });
    onUnmounted(() => {
      window.removeEventListener('resize', () => breakpoint.value.update());
    });
    return {
      createProgramDialog,
      editingCustomScope,
      breakpoint,
      displayScopes,
      tileHeightRate,
      customScope,
      customScopeTitle,
      customScopeDetails,
      submitScope,
      selectScope,
      loadingDataValue,
      loadingData,
      editScope,
      scopeRefs,
      customScopeRef,
      scope__containerRef
    };
  }
});
