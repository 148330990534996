import { RouteConfig } from 'vue-router';
import { useUserGetters, useUserActions } from '@/store';

const { getUserPortfolios } = useUserActions(['getUserPortfolios']);
const { getLatestDocumentByUserId } = useUserActions(['getLatestDocumentByUserId']);
const { getUserPermissions, clearProgramDoc } = useUserActions([
  'getUserPermissions',
  'clearProgramDoc'
]);
const routes: Array<RouteConfig> = [
  {
    path: '/temp-setup-program',
    name: 'tempSetupProgram',
    component: () => import('@/views/Guide/components/SetupWizard/SetupProgramWizard.vue'),
    meta: {
      layout: 'no-nav'
    },
    props: {
      website: 'example.com',
      value: {
        program_schema_version: '1.0',
        adks: [
          {
            name: 'Sample ADK',
            description: 'This is a sample ADK',
            type: 'text',
            required: true
          }
        ]
        // Add other fields as needed
      }
    }
  },
  {
    path: '/temp-setup-scopes',
    name: 'tempSetupScopes',
    component: () => import('@/views/Guide/components/SetupWizard/SetupScopesWizard.vue'),
    meta: {
      layout: 'no-nav'
    },
    props: {
      website: 'tesla.com',
      scopes: [
        'Scope 1. This is the first scope description  This is the first scope description  This is the first scope description  This is the first scope description  This is the first scope description  This is the first scope description ',
        'Scope 2 This is the second scope description This is the second scope description This is the second scope description This is the second scope description',
        'Scope 3 This is the third scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description This is the second scope description'
      ],
      loading: false,
      value: ''
    }
  },
  {
    path: '/activity',
    name: 'ActivityWrapper',
    component: () => import('@/components/activities/activityWrapper.vue'),
    meta: {
      requiresAuth: true,
      layout: 'no-nav'
    }
  },
  {
    path: '/login/:page?',
    name: 'login',
    component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
    meta: {
      layout: 'no-nav'
    },
    beforeEnter(to, from, next) {
      const isSponsor: any = localStorage.getItem('isSponsor');
      const selectedOrg: any = localStorage.getItem('SelectedOrg');
      if (JSON.parse(isSponsor)) {
        next({ name: 'sponsor', query: { ...to.query } });
      }
      if (localStorage.getItem('setRoute') && JSON.parse(selectedOrg)) {
        const toPath = `${localStorage.getItem('setRoute')}?isVerified=true`;
        next({ path: toPath });
      }
      // if (localStorage.getItem('setRoute')) {
      //   const toPath = `${localStorage.getItem('setRoute')}`;
      //   next({ path: toPath });
      // }
      const { getUser } = useUserGetters(['getUser']);
      if (!getUser.value) next();
      else next({ name: 'portfolio' });
    }
  },
  {
    path: '/signup/:page?',
    name: 'signup',
    component: () => import(/* webpackChunkName: "Signup" */ '@/views/Signup.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },
    beforeEnter(to, from, next) {
      // const { getUser } = useUserGetters(['getUser']);
      // if (!getUser.value) next();
      // else next({ name: 'portfolio' });
      if (localStorage.getItem('apollo-token')) next({ name: 'portfolio' });
      else next();
    }
  },
  {
    path: '/monitor/:participantId?',
    name: 'Monitor',
    component: () => import(/* webpackChunkName: "Monitor" */ '@/views/Monitor/Monitor.vue'),
    meta: {
      requiresAuth: true,
      layout: 'no-nav'
    },
    async beforeEnter(to, from, next) {
      if (localStorage?.getItem('permissions')?.includes('can_monitor')) {
        next();
      } else {
        next({ path: '/' });
      }
    }
  },
  {
    path: '/:schemaType/form/:id',
    name: 'AddAdk',
    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },
    component: () => import(/* webpackChunkName: "Builder" */ '@/views/form-builder/Builder.vue')
  },
  {
    path: '/:schemaType/form/:id/:page',
    name: 'EditAdK',
    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },
    component: () => import(/* webpackChunkName: "Builder" */ '@/views/form-builder/Builder.vue')
  },
  {
    path: '/program',
    name: 'Monitor:actions',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/AirMonitor.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/program/manage',
    name: 'Manage',
    component: () =>
      import(/* webpackChunkName: "Manage" */ '@/views/ManageProgram/views/Manage.vue')
  },
  {
    path: '/program/participants',
    name: 'Participants',
    component: () =>
      import(/* webpackChunkName: "Participants" */ '@/views/ManageProgram/views/Participants.vue')
  },
  {
    path: '/program/editprogram',
    name: 'Editprogram',
    component: () =>
      import(/* webpackChunkName: "EditProgram" */ '@/views/ManageProgram/views/EditProgram.vue')
  },
  {
    path: '/program/managestakeholder',
    name: 'ManageStakeholder',
    component: () =>
      import(
        /* webpackChunkName: "ManageStakeholder" */ '@/views/ManageProgram/views/ManageStakeholder.vue'
      )
  },
  {
    path: '/program2',
    name: 'ProgramManage',
    component: () =>
      import(/* webpackChunkName: "ProgramManage" */ '@/views/ManageProgram/ProgramManage.vue')
  },
  {
    path: '/ListView',
    name: 'Listview',
    component: () =>
      import(/* webpackChunkName: "ListView" */ '@/views/ManageProgram/components/ListView.vue')
  },
  {
    path: '/nav',
    name: 'Nav',
    component: () =>
      import(/* webpackChunkName: "Nav" */ '@/views/ManageProgram/components/Nav.vue')
  },
  {
    path: '/explore',
    name: 'explore',
    // component: () => import(/* webpackChunkName: "Explore" */ '@/views/Explore/Guide.vue'),
    component: () => import(/* webpackChunkName: "Explore" */ '@/views/Landing.vue'),
    meta: {
      layout: 'no-nav'
    },
    async beforeEnter(to, from, next) {
      if (localStorage?.getItem('permissions')?.includes('can_explore_programs')) {
        next();
      } else {
        next({ path: '/' });
      }
    }
  },
  {
    path: '/scope',
    name: 'scope',
    component: () =>
      import(/* webpackChunkName: "Explore" */ '@/components/molecules/m-build-scope.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/guide/:programId/:page',
    name: 'guide',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Guide.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },

    props: route => ({
      studentId: route.query.studentId,
      programId: route.params.programId,
      page: Number(route.params.page),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true'
    }),

    async beforeEnter(to, from, next) {
      clearProgramDoc();
      next();
    }
  },
  {
    path: '/program/:program_name?',
    name: 'program',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Setup.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },

    props: route => ({
      studentId: route.query.studentId,
      program_name: route.params.program_name,
      page: Number(route.params.page),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true',
      adk: route.query.adk,
      type: 'program'
    })
  },
  {
    path: '/program/:program_name',
    name: 'program',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Setup.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },

    props: route => ({
      studentId: route.query.studentId,
      program_name: route.params.program_name,
      page: Number(route.params.page || 0),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true',
      adk: route.query.adk,
      type: 'program'
    })
  },
  // {
  //   path: '/program-setup/:program_name?',
  //   name: 'programSetup',
  //   component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Setup.vue'),

  //   meta: {
  //     layout: 'no-nav',
  //     requiresAuth: true,
  //     requiresUser: true
  //   },

  //   props: route => ({
  //     program_name: route.params.program_name,
  //     monitorOrganizerView: route.query.monitorOrganizerView === 'true',
  //     previewView: route.query.preview === 'true',
  //     adk: route.query.adk,
  //     type: 'program-setup'
  //   })
  // },
  // {
  //   path: '/program-setup/:program_name',
  //   name: 'programSetup',
  //   component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Setup.vue'),

  //   meta: {
  //     layout: 'no-nav',
  //     requiresAuth: true,
  //     requiresUser: true
  //   },

  //   props: route => ({
  //     program_name: route.params.program_name,
  //     monitorOrganizerView: route.query.monitorOrganizerView === 'true',
  //     previewView: route.query.preview === 'true',
  //     adk: route.query.adk,
  //     type: 'program-setup'
  //   })
  // },
  {
    path: '/public-program/:program_name?',
    name: 'publicProgram',
    component: () => import('@/views/Guide/Setup.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false,
      showBasicUserInfo: true
    },
    props: route => ({
      studentId: route.query.studentId,
      program_name: route.params.program_name,
      page: Number(route.params.page),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true',
      adk: route.query.adk,
      type: 'public-program'
    })
  },
  {
    path: '/public-program/:program_name',
    name: 'publicProgram',
    component: () => import('@/views/Guide/Setup.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false,
      showBasicUserInfo: true
    },
    props: route => ({
      studentId: route.query.studentId,
      program_name: route.params.program_name,
      page: Number(route.params.page || 0),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true',
      adk: route.query.adk,
      type: 'public-program'
    })
  },
  {
    path: '/demo-program/:program_name?',
    name: 'demoProgram',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Setup.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },

    props: route => ({
      studentId: route.query.studentId,
      program_name: route.params.program_name,
      page: Number(route.params.page),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true',
      adk: route.query.adk,
      type: 'demo-program'
    })
  },
  {
    path: '/demo-program/:program_name',
    name: 'demoProgram',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/Setup.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },

    props: route => ({
      studentId: route.query.studentId,
      program_name: route.params.program_name,
      page: Number(route.params.page || 0),
      monitorOrganizerView: route.query.monitorOrganizerView === 'true',
      previewView: route.query.preview === 'true',
      adk: route.query.adk,
      type: 'demo-program'
    })
  },
  {
    path: '/:schemaType/details/:name',
    name: 'Details',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Details.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },

    props: route => ({
      program_name: route.params.name,
      schemaType: route.params.schemaType,
      isTemplate: route.params.isTemplate || route.params.schemaType === 'template'
    })
  },
  {
    path: '/guide',
    name: 'guide:airtime',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/AirGuide.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/guide/:activityName',
    name: 'guide:activity',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/ActivityGuide.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },

    props: route => ({
      activityName: route.params.activityName
    })
  },
  {
    path: '/portfolio',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    },
    props: () => ({
      isPortfolio: true
    }),
    async beforeEnter(to, from, next) {
      const { getUser } = useUserGetters(['getUser']);
      const user = getUser.value;
      if (user && user?.userTypes && user?.userTypes?.length >= 1) {
        const response = await getUserPermissions();
        if (response) {
          localStorage.setItem('permissions', response?.permissions);
        }
        if (response?.permissions?.includes('can_see_portfolio')) {
          if (localStorage.getItem('setRoute') && JSON.parse(localStorage.getItem('SelectedOrg'))) {
            next({ name: 'wallet' });
          } else if (localStorage.getItem('setRoute')) {
            const toPath = `${localStorage.getItem('setRoute')}`;
            next({ path: toPath });
          } else if (localStorage.getItem('setDemoProgramRoute')) {
            const toPath = `${localStorage.getItem('setDemoProgramRoute')}`;
            next({ path: toPath });
          } else {
            next();
          }
        } else {
          next({ path: '/' });
        }
      } else {
        next({ path: '/setup' });
      }
    }
  },
  {
    path: '/wallet',
    name: 'wallet',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    },
    props: () => ({
      isWallet: true
    })
  },
  {
    path: '/join/:orgId',
    name: 'pilotcityInvite',
    component: () =>
      import(/* webpackChunkName: "Portfolio" */ '@/views/Sponsor/components/PublicSponsor.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/profile-setting',
    name: 'profileSetting',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    },
    props: () => ({
      isSetting: true
    })
  },
  {
    path: '/badges',
    name: 'badges',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    },
    props: () => ({
      isBadges: true
    })
  },
  {
    path: '/citizen-profile',
    name: 'citizenProfile',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    },
    props: () => ({
      isCitizen: true
    })
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    },
    props: () => ({
      isPayment: true
    })
  },
  {
    path: '/portfolio/:component/:profile?',
    name: 'portfolio',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Portfolio.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/invite/:slug',
    name: 'invitation',
    component: () =>
      import(/* webpackChunkName: "Invite" */ '@/views/Portfolio/views/GlobalInvitation.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import(/* webpackChunkName: "Setup" */ '@/views/Portfolio/SetupPortfolio.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: true
    },
    async beforeEnter(to, from, next) {
      const { getUser } = useUserGetters(['getUser']);
      const user = getUser.value;
      if (user) {
        const portfoliosData = await getUserPortfolios({ user_id: user._id.toString() });
        if (portfoliosData && user.userTypes?.every(type => portfoliosData[type!])) {
          if (localStorage.getItem('guardian-consent')) {
            next({
              name: 'sign',
              params: { studentId: `${localStorage.getItem('guardian-consent')}` }
            });
          } else if (localStorage.getItem('sponsorShipLinkPath')) {
            const sponsorShipLinkPath = `${localStorage.getItem('sponsorShipLinkPath')}`;
            next({ path: sponsorShipLinkPath });
          } else if (localStorage.getItem('isAutomationRoute')) {
            next({ name: 'landing' });
          } else if (localStorage.getItem('setRoute')) {
            const toPath = `${localStorage.getItem('setRoute')}`;
            next({ path: toPath });
          } else if (localStorage.getItem('setDemoProgramRoute')) {
            const toPath = `${localStorage.getItem('setDemoProgramRoute')}`;
            next({ path: toPath });
          } else {
            const res = await getLatestDocumentByUserId({
              document_type: 'Program',
              user_id: user._id.toString()
            });
            if (res) {
              next({
                name: 'guide',
                params: {
                  programId: res.document._id.toString(),
                  page: `${res.lastCompletedAdkIndex}`
                }
              });
            } else {
              next({ name: 'portfolio' });
            }
          }
        } else if (localStorage.getItem('setDemoProgramRoute')) {
          const toPath = `${localStorage.getItem('setDemoProgramRoute')}`;
          next({ path: toPath });
        } else {
          next();
        }
      }
      next();
    }
  },
  {
    path: '/setup/:activityName',
    name: 'setup:activity',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/AirSetup.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },

    props: route => ({
      activityName: route.params.activityName
    })
  },
  {
    path: '/balanceview',
    name: 'balanceview',
    component: () =>
      import(/* webpackChunkName: "BalanceView" */ '@/views/Portfolio/BalanceView.vue')
  },
  {
    path: '/create-program',
    name: 'createProgram',
    component: () => import(/* webpackChunkName: "CreateProgram" */ '@/views/CreateProgram.vue'),
    meta: {
      requiresAuth: true,
      requiresUser: true
    }
  },
  {
    path: '/ai-program-designer',
    name: 'aiProgramDesigner',
    component: () =>
      import(/* webpackChunkName: "CreateProgram" */ '@/views/AIProgramDesigner.vue'),
    meta: {
      requiresAuth: true
      // requiresUser: true
    }
  },
  {
    path: '/timeline',
    name: 'timeline',
    component: () => import(/* webpackChunkName: "Timeline" */ '@/components/Timeline.vue')
  },
  {
    path: '/sign/:studentId',
    name: 'sign',
    component: () => import(/* webpackChunkName: "Signup" */ '@/views/Agreement.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },
    beforeEnter(to, from, next) {
      const { getUser } = useUserGetters(['getUser']);
      if (!getUser.value) {
        localStorage.setItem('guardian-consent', to.params.studentId);
        localStorage.setItem('is-guardian', to.params.studentId);
        next({
          name: 'login'
        });
      } else next();
    }
  },
  {
    path: '/',
    name: 'landing',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Landing.vue'),
    props: route => ({
      preSelectedTemplate: route.query.template
    }),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/ecc2022',
    name: 'Ecc2022',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Ecc2022.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/stemday',
    name: 'StemDay',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/StemDay.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/employer',
    name: 'EmployerLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/EmployerLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/help/employer',
    name: 'EmployerHelpLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/EmployerHelpLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/teacher',
    name: 'TeacherLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/TeacherLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/help/teacher',
    name: 'TeacherHelpLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/TeacherHelpLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/student',
    name: 'StudentLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/StudentLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },

  {
    path: '/stories',
    name: 'Stories',
    component: () => import(/* webpackChunkName: "Landing" */ '../views/Stories.vue'),
    meta: {
      layout: 'no-nav'
    }
  },

  {
    path: '/story',
    name: 'Story',
    component: () => import(/* webpackChunkName: "Landing" */ '../views/Story.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/show',
    name: 'Show',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Show.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/help/student',
    name: 'StudentHelpLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/StudentHelpLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/parent',
    name: 'ParentLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/ParentLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/school',
    name: 'SchoolLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/SchoolLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/partner',
    name: 'PartnerLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/PartnerLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  // {
  //   path: '/sponsor',
  //   name: 'SponsorLanding',
  //   component: () => import(/* webpackChunkName: "Landing" */ '@/views/SponsorLanding.vue'),
  //   meta: {
  //     layout: 'no-nav'
  //   }
  // },
  {
    path: '/onboard2023',
    name: 'Onboard2023',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Onboard2023.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/sponsor',
    name: 'SponsorLanding',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/SponsorLanding.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/alumni',
    name: 'Alumni',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Alumni.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  // TEAM SAFETY, RULES, ETC
  {
    path: '/gate510safetytraining',
    name: 'Gate510safetytraining',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Gate510safetytraining.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  // CONTRIBUTOR / ALUMNI TEAM ORIENTATION
  {
    path: '/orientation',
    name: 'AlumniTeamOrientation',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/AlumniTeamOrientation.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/contributor',
    name: 'contributor',
    component: () =>
      import(/* webpackChunkName: "Portfolio" */ '@/views/Portfolio/Contributor2.vue'),
    meta: {
      // requiresAuth: true,
      layout: 'no-nav'
      // requiresUser: true
    }
  },
  // EVENTS 2022
  {
    path: '/ebia2022',
    name: 'Ebia2022',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Ebia2022.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/airtime2022recap',
    name: 'Airtime2022',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Airtime2022.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/airtime2022',
    name: 'Airtime2022',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Airtime2022.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/sponsorpage',
    name: 'SponsorPage',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/SponsorPage.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/successteam',
    name: 'SuccessTeam',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/SuccessTeam.vue'),
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/getsponsored',
    name: 'ApproveSponsor',
    component: () => import('@/views/ApproveSponsor.vue'),
    meta: {
      requiresAuth: true,
      layout: 'no-nav'
    }
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('@/views/Mobile.vue'),
    meta: {
      requiresAuth: true,
      layout: 'no-nav'
    }
  },
  {
    path: '/monitor-internship',
    name: 'MobileEmployer',
    component: () => import('@/views/MobileEmployer.vue'),
    meta: {
      requiresAuth: true,
      layout: 'no-nav'
    }
  },
  {
    path: '/sponsor/:shareCode',
    name: 'Sponsor',
    // component: () => import(/* webpackChunkName: "Guide" */ '@/views/Explore/Guide.vue'), // ! change this explore programs!!
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Landing.vue'), // ! change this explore programs!!
    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },
    // beforeEnter(to, from, next) {
    //   const { getUser } = useUserGetters(['getUser']);
    //   if (getUser.value) {
    //     next();
    //   } else {
    //     localStorage.setItem('sponsorShipLinkPath', to.path);
    //     next({ name: 'login' });
    //   }
    // },
    props: route => ({
      shareCode: route.params.shareCode
    })
  },
  {
    path: '/invite/:schoolName',
    name: 'invite',
    component: () => import(/* webpackChunkName: "Invite" */ '@/views/Invite.vue'),
    props: true,
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/invites/:partnerName',
    name: 'partnerInvite',
    component: () => import(/* webpackChunkName: "Invite" */ '@/views/PartnerInvite.vue'),
    props: true,
    meta: {
      layout: 'no-nav'
    }
  },
  {
    path: '/emailconfirmation',
    name: 'confirmEmail',
    component: () => import(/* webpackChunkName: "ConfirmEmail" */ '@/views/ConfirmEmail.vue'),
    props: route => ({
      token: route.query.token,
      tokenId: route.query.tokenId,
      username: route.query.username
    })
  },
  {
    path: '/changepassword',
    name: 'passwordChange',
    component: () => import(/* webpackChunkName: "ChangePassword" */ '@/views/ChangePassword.vue')
  },
  {
    path: '/discordconfirmation',
    name: 'confirmDiscord',
    component: () => import(/* webpackChunkName: "ConfirmDiscord" */ '@/views/ConfirmDiscord.vue'),
    props: route => ({
      code: route.query.code
    }),
    meta: {
      layout: 'no-nav',
      requiresAuth: false
    }
  },
  {
    path: '/password-reset',
    name: 'resetPassword',
    component: () => import(/* webpackChunkName: "ResetPassword" */ '@/views/ResetPassword.vue'),
    props: route => ({
      token: route.query.token,
      tokenId: route.query.tokenId
    })
  },
  {
    path: '/meetings',
    name: 'meetings',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/MeetingEvents.vue'),
    meta: {
      requiresAuth: false,
      requiresUser: false
    }
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/PrivacyPolicy.vue')
  },
  {
    path: '/terms-and-conditions',
    name: 'terms-and-conditions',
    component: () => import(/* webpackChunkName: "Portfolio" */ '@/views/TermsAndConditions.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '@/views/Error404.vue')
  },
  {
    path: '/authRequired',
    name: 'authError',
    component: () => import(/* webpackChunkName: "ErrorLogin" */ '@/views/ErrorLogin.vue')
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import(/* webpackChunkName: "maintenance" */ '@/views/Maintenance.vue'),
    meta: {
      requiresAuth: false,
      layout: 'no-nav'
    }
  },
  {
    path: '/template/:templateId/:page',
    name: 'templateSetup',
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Guide/TemplateSetup.vue'),

    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },

    props: route => ({
      templateId: route.params.templateId,
      page: Number(route.params.page)
    })
  },
  {
    path: '/sponsor-programs/:user_id',
    name: 'sponsorProgramsPage',
    component: () => import(/* webpackChunkName: "Landing" */ '@/views/Landing.vue'),
    meta: {
      layout: 'no-nav',
      requiresAuth: false,
      requiresUser: false
    },
    props: route => ({
      userId: route.params.user_id,
      template: route.query.template,
      pathways: route.query.pathways,
      location: route.query.location
    })
  },
  {
    path: '/set-password',
    name: 'setPassword',
    component: () => import(/* webpackChunkName: "SetPassword" */ '@/views/SetPassword.vue'),
    props: route => ({
      token: route.query.token
    })
  },
  {
    path: '/sponsor/:shareCode/:id',
    name: 'EditSponsor',
    // component: () => import(/* webpackChunkName: "Guide" */ '@/views/Explore/Guide.vue'), // ! change this explore programs!!
    component: () => import(/* webpackChunkName: "Guide" */ '@/views/Landing.vue'), // ! change this explore programs!!
    meta: {
      layout: 'no-nav',
      requiresAuth: true,
      requiresUser: true
    },
    // beforeEnter(to, from, next) {
    //   const { getUser } = useUserGetters(['getUser']);
    //   if (getUser.value) {
    //     next();
    //   } else {
    //     localStorage.setItem('sponsorShipLinkPath', to.path);
    //     next({ name: 'login' });
    //   }
    // },
    props: route => ({
      shareCode: route.params.shareCode,
      isEdit: route.params.id === 'edit'
    })
  },
  {
    path: '/temp-setup-site',
    name: 'tempSetupSite',
    component: () => import('@/views/Guide/components/SetupWizard/SetupSiteWizard.vue'),
    meta: {
      layout: 'no-nav'
    },
    props: {
      loading: false,
      errors: false
    }
  }
];

export default routes;
