
























































import { defineComponent, reactive, ref, computed, onMounted } from '@vue/composition-api';
import { useUserActions, useToolActions, useUserGetters, useUserState } from '@/store';
import SetupScopesWizard from './SetupScopesWizard.vue';
import SetupSiteWizard from './SetupSiteWizard.vue';
// import useWebSocket from './wsManager';
// import SetupProgramWizard from './SetupProgramWizard.vue';

export default defineComponent({
  name: 'SetupWizard',
  components: {
    'setup-site': SetupSiteWizard,
    'setup-scopes': SetupScopesWizard,
    // 'setup-program': SetupProgramWizard,
    OnboardDialog: () => import('@/components/molecules/m-onboarding.vue')
  },
  props: {
    enabled: {
      type: Boolean,
      required: false,
      default: false
    },
    programDoc: {
      type: Object,
      required: false,
      default: () => {}
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    isDisplay: {
      type: Boolean,
      default: false
    }
  },
  setup(props: any, ctx: any) {
    const { user }: any = useUserState(['user']);
    const { createAutomationProgram, simulateScope, simulateProgramDetails, updateAutomationAdk } =
      useUserActions([
        'createAutomationProgram',
        'simulateScope',
        'simulateProgramDetails',
        'updateAutomationAdk'
      ]);
    // const authToken = localStorage.getItem('apollo-token');
    // const { getUserType } = useUserGetters(['getUserType']);
    // const defaultOpenCondition = ref(
    //   Boolean(
    //     !props.programDoc.data.adks
    //       .find(adk => adk?.name === 'start')
    //       .fields.find(field => field.model === 'programDesc').setup.programDesc
    //   )
    // );
    const defaultOpenCondition = ref(true);
    if (
      !props?.programDoc?.data?.adks
        ?.find(adk => adk?.name === 'start')
        ?.fields?.find(field => field?.model === 'programDesc')?.setup?.programDesc
    ) {
      defaultOpenCondition.value = false;
    }
    const modalToggled = ref(props.defaultOpen && props.enabled && defaultOpenCondition?.value);
    const automateSetupModal = computed<boolean>({
      get: () => {
        return modalToggled?.value;
      },
      set: value => {
        modalToggled.value = Boolean(value);
      }
    });
    const isOnboarding = ref(false);
    function toggleDialog(val) {
      isOnboarding.value = val;
    }
    const stage = computed(() => {
      switch (process.env.VUE_APP_NODE_ENV) {
        case 'development':
          return 'dev';
        case 'stage':
          return 'stage';
        case 'production':
          return 'prod';
        default:
          return 'dev';
      }
    });
    // const authToken = localStorage.getItem('apollo-token');
    // const wizardURL = `wss://automate-setup-service-${stage.value}.ws.pilotcity.com/`;
    // const socket = useWebSocket({ authToken: `${authToken}`, url: wizardURL });
    enum SETUP_STATE {
      'SITE',
      'SCOPE'
    }
    const setupState = SETUP_STATE.SITE;
    const projectScopes = reactive([]);
    const website = ref('');
    const selectedScope = ref('');
    const newProgramData = reactive({});
    const siteLoading = ref(false);
    const programLoading = ref(false);
    const errors = ref(false);
    const finishedUpdate = ref(false);

    function openModal() {
      automateSetupModal.value = true;
      errors.value = false;
      siteLoading.value = false;
      programLoading.value = false;
      errors.value = false;
      // socket.connect();
    }
    onMounted(() => {
      if (props?.isDisplay) {
        openModal();
      }
      // if (automateSetupModal.value) socket.connect();
    });
    function closeModal() {
      automateSetupModal.value = false;
      this.website = '';
      this.projectScopes = [];
      this.error = false;
      // socket.disconnect();
      setTimeout(() => this.resetSetupState(), 100);
      ctx.emit('close');
    }
    async function submitSiteHandler(site: string) {
      // if (!socket.socketInstance) socket.connect();
      siteLoading.value = true;
      const siteTrimRegex = /^(?:https?:\/\/)?(?:[^./]+\.)?([^/]+\.[^./]+)/;
      if (!site || !site.match(siteTrimRegex)) {
        this.alertUser('Please enter a valid website url to continue', 'error');
        siteLoading.value = false;
        return;
      }
      [this.website] = site.match(siteTrimRegex)!;
      // non-null assertion because typescript cannot infer the type of an external module declared in javascript's runtime environment
      // during compilation
      await this.requestProgramScopes();
      siteLoading.value = false;
      if (this.projectScopes && this.projectScopes.length > 0) this.incrementState();
    }

    async function submitScopeHandler() {
      if (user?.value) {
        if (
          !user?.value?.userTypes?.includes('Employer') &&
          !user?.value?.userTypes?.includes('Teacher')
        ) {
          this.alertUser('User must be host to create automation program!', 'error');
          this.automateSetupModal = false;
        } else {
          programLoading.value = true;
          const { website, selectedScope: scope } = this;
          if (!scope) {
            this.alertUser('Please select a program before continuing', 'error');
            programLoading.value = false;
            return;
          }
          const data = {
            companySite: website,
            programScope: scope
          };
          // ctx.emit('openCreateProgram', data);
          await this.requestProgramDetails(data).then(() => this.requestProgramUpdate());
          if (!this.finishedUpdate) {
            this.alertUser('Something went wrong', 'error');
            return;
          }
          this.closeModal();
          if (this?.programDoc?.data) {
            // eslint-disable-next-line no-restricted-globals
            location.reload();
          }
          // const { fetchProgramById } = useUserActions(['fetchProgramById']);
          // await fetchProgramById({ program_id: props.programDoc.data._id });
        }
      } else {
        isOnboarding.value = true;
        localStorage.setItem('isAutomationRoute', 'true');
      }
    }
    /** BEGIN API I/O */
    async function requestProgramScopes() {
      const { website } = this;
      const quantity = 6; // default
      try {
        // const res: any = await socket.sendMessage({
        //   action: 'scope',
        //   data: {
        //     companySite: website,
        //     scopeQty: quantity
        //   }
        // });
        const res = await simulateScope({
          companySite: website,
          scopeQty: quantity
        });
        this.projectScopes.push(...res);
      } catch (err) {
        const notFoundRegex = /Apollo data not found/;
        const openAIRegex = /Something went wrong with scope completion/;
        const isAIError = openAIRegex.test(err.message);
        const isNotFound = notFoundRegex.test(err.message);
        if (isNotFound)
          this.alertUser(
            'Unable to retrieve data for this site, please check spelling. Otherwise, we are unable to automatically generate a program for this organization at this time.',
            'error'
          );
        else if (isAIError)
          this.alertUser(
            'ERROR: Unable to generate projects at this time, Please try again later.',
            'error'
          );
        else {
          this.alertUser(`${err.message}, please try again`, 'error');
          this.automateSetupModal = false;
        }
        console.error('Error with requesting scopes: ', err);
        siteLoading.value = false;
      }
    }
    const modifyProgramName = (programName: string) => {
      // const epochTime = new Date().getTime();
      // return `${programName}_${epochTime}`;
    };
    async function requestProgramUpdate() {
      const newProgram = { ...this.newProgramData };
      try {
        if (this.newProgramData !== 'You are unable to create program') {
          newProgram.programType = 'automation';
          if (this?.programDoc?.data) {
            const { _id: programId } = this.programDoc.data;
            console.warn(programId, newProgram.adks);
            // await socket.sendMessage({
            //   action: 'adks',
            //   data: {
            //     programId,
            //     adks: newProgram.adks
            //   }
            // });
            await updateAutomationAdk({
              programId,
              adks: newProgram.adks
            });
          } else {
            newProgram.userId = user?.value?._id?.toString();
            // const programData = await createAutomationProgram(newProgram);
            ctx.emit('openCreateProgram', newProgram);
            // ctx.root.$router.push(`/guide/${programData?.insertedId?.toString()}/0`);
          }
        } else {
          this.alertUser(this.newProgramData, 'error');
        }
      } catch (err) {
        console.error('Error while trying to update program: ', err);
        programLoading.value = false;
      }
      this.finishedUpdate = true;
    }
    async function requestProgramDetails(data) {
      try {
        // const res = await socket.sendMessage({
        //   action: 'details',
        //   data
        // });
        const res = await simulateProgramDetails(data);
        this.newProgramData = res;
      } catch (err) {
        console.error('Error while trying to generate program: ', err);
        const messageRegex = /^(?!.*error).*\n(.*)/i;
        const match = err.message.match(messageRegex);
        const output = match[1];
        this.alertUser(output, 'error');
      }
    }
    /** END API I/O */
    /** BEGIN HELPERS */
    async function alertUser(message, type) {
      if (type === 'error') errors.value = true;
      const { showSnackbar } = useToolActions(['showSnackbar']);
      showSnackbar({
        message,
        type,
        isShowSnackbar: true
      });
    }
    function incrementState() {
      errors.value = false;
      if (this.setupState === this.SETUP_STATE.SITE) this.setupState = this.SETUP_STATE.SCOPE;
      else if (this.setupState === this.SETUP_STATE.SCOPE) this.setupState = this.SETUP_STATE.SAVE;
      else this.automateSetupModal = false;
    }
    function resetSetupState() {
      this.setupState = SETUP_STATE.SITE;
    }

    const outSideClick = event => {
      // socket.disconnect(event);
      ctx.emit('close');
    };
    /** END HELPERS */
    return {
      website,
      modalToggled,
      newProgramData,
      programLoading,
      siteLoading,
      setupState,
      projectScopes,
      automateSetupModal,
      selectedScope,
      SETUP_STATE,
      errors,
      submitSiteHandler,
      submitScopeHandler,
      requestProgramDetails,
      requestProgramScopes,
      requestProgramUpdate,
      finishedUpdate,
      alertUser,
      incrementState,
      resetSetupState,
      closeModal,
      openModal,
      // socket,
      defaultOpenCondition,
      outSideClick,
      toggleDialog,
      isOnboarding
    };
  }
});
