var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{ref:"scope__containerRef",staticClass:"program-setup-wizard__scope program-setup-wizard__dialog"},[_c('v-card-title',[_c('v-row',{staticClass:"pa-4 mt-1",attrs:{"no-gutter":""}},[_c('h2',{},[_c('v-icon',{staticClass:"mr-2",attrs:{"large":"","color":"yellow"}},[_vm._v("mdi-lightning-bolt")]),_vm._v("Select or create scope ")],1),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"font-weight-bold ml-3",attrs:{"depressed":"","dark":"","rounded":"","href":'https://' + _vm.website,"target":"_blank","rel":"noopener"}},'v-btn',attrs,false),on),[_c('span',{staticClass:"font-weight-regular mr-1"},[_vm._v("For")]),_vm._v(" "+_vm._s(_vm.website)+" ")])]}}])},[_c('span',[_vm._v("Go to website")])])],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',{staticStyle:{"flex-direction":"column"}},[_vm._l((_vm.displayScopes),function(scope,index){return _c('v-col',{key:index,ref:"scopeRefs",refInFor:true,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"program-setup-wizard__card pa-3",class:_vm.value == scope.base && (_vm.value != _vm.customScope || !_vm.editingCustomScope)
                ? 'program-setup-wizard__card--focused'
                : '',staticStyle:{"position":"relative"},attrs:{"ripple":{ center: true }},on:{"click":function($event){$event.preventDefault();return (function ($event) {
                if ($event.target.closest('.v-btn') === null) {
                  _vm.editScope(scope.base);
                }
              }).apply(null, arguments)}}},[_c('v-card-title',{staticClass:"program-setup-wizard__card--title"},[_c('h4',{staticClass:"h3-style"},[_vm._v(_vm._s(scope.base))])])],1)],1)}),_c('v-col',{staticClass:"text-center mb-3"},[_c('h2',[_vm._v("OR")])]),_c('v-col',{ref:"customScopeRef",attrs:{"cols":"12"}},[_c('v-card',{staticClass:"program-setup-wizard__card-custom program-setup-wizard__card pa-3",class:[
              _vm.customScope ? 'program-setup-wizard__card--focused' : '',
              _vm.value && _vm.value == _vm.customScope ? 'program-setup-wizard__card--focused' : ''
            ],attrs:{"ripple":_vm.editingCustomScope ? false : { center: true }},on:{"click":function($event){$event.preventDefault();return (function ($event) { return _vm.selectScope(_vm.customScope); }).apply(null, arguments)}}},[(_vm.editingCustomScope || !_vm.customScope)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-textarea',{staticClass:"no-outline h3-style",attrs:{"auto-grow":"","rows":"4","color":"yellow","full-width":"","hide-details":"","outlined":"","label":"Edit or create custom scope"},model:{value:(_vm.customScope),callback:function ($$v) {_vm.customScope=$$v},expression:"customScope"}})],1)],1):_vm._e(),(!_vm.editingCustomScope && _vm.customScope)?_c('v-row',{staticClass:"custom-scope-row pb-4 pt-4",attrs:{"no-gutters":""},on:{"click":function($event){_vm.editingCustomScope = true}}},[_c('v-col',{staticStyle:{"cursor":"pointer"},attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"program-setup-wizard__card--title"},[_c('h4',{staticClass:"h3-style"},[_vm._v(_vm._s(_vm.customScope))])])],1),_c('div',{staticClass:"custom-scope-overlay"},[_c('v-icon',{attrs:{"left":"","large":"","color":"white"}},[_vm._v("mdi-pencil-outline")]),_c('span',[_vm._v("EDIT")])],1)],1):_vm._e(),(_vm.editingCustomScope || !_vm.customScope)?_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"d-flex align-center",attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"font-weight":"bold","letter-spacing":"0.1em !important","border-radius":"1em !important"},attrs:{"block":"","x-large":"","dark":"","depressed":"","color":"yellow"},on:{"click":function ($event) {
                      _vm.selectScope(_vm.customScope);
                      _vm.editingCustomScope = false;
                    }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-check")]),_vm._v(" Finish Editing ")],1)],1)],1):_vm._e()],1)],1)],2)],1)],1),_c('v-card-actions',[_c('v-row',{staticClass:"pt-4 pb-4 pr-4 pl-4",attrs:{"justify":"end","no-gutters":""}},[_c('v-col',{staticClass:"mr-4"},[_c('v-btn',{staticStyle:{"border-radius":"1em !important"},attrs:{"block":"","depressed":"","x-large":"","outlined":"","rounded":""},on:{"click":function ($event) { return _vm.$emit('closeModal'); }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-cancel")]),_vm._v("Cancel ")],1)],1),_c('v-col',[_c('v-dialog',{attrs:{"attach":".program-setup-wizard__scope","hide-overlay":"","persistent":"","width":"300","height":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"white--text font-weight-bold",staticStyle:{"border-radius":"1em !important"},attrs:{"x-large":"","color":"green","disabled":!_vm.value,"block":"","depressed":"","rounded":""},on:{"click":function($event){$event.preventDefault();return _vm.submitScope.apply(null, arguments)}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-circle")]),(_vm.loading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"24","width":"3"}}):_c('span',[_vm._v("Create Program")])],1)]}}]),model:{value:(_vm.createProgramDialog),callback:function ($$v) {_vm.createProgramDialog=$$v},expression:"createProgramDialog"}},[_c('v-card',{staticClass:"setup-program-wizard__scope--loading-card",staticStyle:{"color":"white"},attrs:{"color":"yellow","dark":""}},[_c('v-card-text',{staticStyle:{"letter-spacing":"0.5px","text-align":"center"}},[_c('v-icon',{staticClass:"mb-4 mt-4",attrs:{"size":"48"}},[_vm._v("mdi-cog")]),_c('h3',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.loadingDataValue.message))]),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"stream":_vm.loadingDataValue.value < 1,"buffer-value":"0","value":_vm.loadingDataValue.value,"color":"white"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }