import axios from 'axios';
import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../../state';
import { MutationTypes } from './mutations';
import programState from './state';

type ProgramActionCtx = ActionContext<typeof programState, RootState>;

export interface ProgramActions extends ActionTree<typeof programState, RootState> {
  fetchTemplates: (ctx: ProgramActionCtx) => Promise<void>;
  fetchPathways: (ctx: ProgramActionCtx) => Promise<void>;
  fetchAllPublishedPrograms: (ctx: ProgramActionCtx, data) => Promise<void>;
  regenerateSponsorLink: (ctx: ProgramActionCtx, data) => Promise<void>;
  toggleSponsorLinkSwitch: (ctx: ProgramActionCtx, data) => Promise<void>;
}

export const actions: ProgramActions = {
  async fetchTemplates({ commit, dispatch }) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.get(`${API_ENDPOINT}/get-templates`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.SET_TEMPLATES, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async fetchPathways({ commit, dispatch }) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      commit(MutationTypes.SET_PATHWAYS, []);
      const resp = await axios.get(`${API_ENDPOINT}/get-pathways`, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.SET_PATHWAYS, resp.data.data);
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async fetchAllPublishedPrograms({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    commit(MutationTypes.SET_INDS, []);
    try {
      const resp = await axios.get(`${API_ENDPOINT}/published-program`, {
        params: {
          ...data.payload
        },
        headers: {
          'Content-Type': 'application/json'
        }
      });
      commit(MutationTypes.SET_INDS, {
        pathways: data.pathways,
        industries: resp?.data?.data?.industries
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async saveSearchPreference({ commit, dispatch }, payload) {
    const API_ENDPOINT = process.env.VUE_APP_PROGRAM_DOC;
    try {
      const resp = await axios.post(`${API_ENDPOINT}/search`, payload, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async regenerateSponsorLink({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_SPONSOR_SERVICE;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/regenerate`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (data.link_type === 'personal') commit('user/LOGIN_USER', resp.data.data, { root: true });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async toggleSponsorLinkSwitch({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_SPONSOR_SERVICE;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/sponsor-switch`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
          'Content-Type': 'application/json'
        }
      });
      if (data.type === 'User') commit('user/LOGIN_USER', resp.data.data, { root: true });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async toggleAutoSponsoringSwitch({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_SPONSOR_SERVICE;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/auto-sponsoring-switch`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
          'Content-Type': 'application/json'
        }
      });
      if (data.type === 'User') commit('user/LOGIN_USER', resp.data.data, { root: true });
      return resp.data.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async toggleSponsorLinkStatus({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_SPONSOR_SERVICE;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/sponsor-link/status`, data, {
        headers: {
          authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
          'Content-Type': 'application/json'
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  },

  async saveSponsorshipCriteria({ commit, dispatch }, data) {
    const API_ENDPOINT = process.env.VUE_APP_SPONSOR_SERVICE;
    try {
      const resp = await axios.patch(`${API_ENDPOINT}/sponsorship-criteria`, data, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      return resp.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
};
